import GrahamScan from '@lucio/graham-scan';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ConvertHelper } from "../../../helpers/convertHelper";
import { NumberHelper } from "../../../helpers/numberHelper";
import { ICoordinates } from "../../../model/navigation/ICoordinates";
import { IStartMap } from '../../maps/models/istart-map';

/** Permet de mettre à disposition des méthodes pour aider à manipuler des données de géolocalisation. */
export abstract class GeolocationHelper {

	//#region METHODS

	private constructor() { }

	/** Calcul la distance entre deux coordonnées (vol d'oiseau) en kilmètres et retourne le résultat, `NaN` si un des paramètres n'est pas bon.
	 * @param pnSourceLatitude Latitude de la coordonnées d'origine (en degrés).
	 * @param pnSourceLongitude Longitude de la coordonnées d'origine (en degrés).
	 * @param pnTargetLatitude Latitude de la coordonnée cible (en degrés).
	 * @param pnTargetLongitude Longitude de la coordonnée cible (en degrés).
	 */
	public static calculateDistanceBetweenCoordinatesKm(pnSourceLatitude: number, pnSourceLongitude: number,
		pnTargetLatitude: number, pnTargetLongitude: number): number {

		if (NumberHelper.areValid([pnSourceLatitude, pnSourceLongitude, pnTargetLatitude, pnTargetLongitude]))
			return ConvertHelper.angularDistanceRadianToKilometers(
				this.calculateAngularDistanceRadian(pnSourceLatitude, pnSourceLongitude, pnTargetLatitude, pnTargetLongitude!)
			);

		else
			return NaN;
	}

	/** Calcul la distance entre deux coordonnées (vol d'oiseau) en kilmètres et retourne le résultat, `NaN` si un des paramètres n'est pas bon.
	 * @param poParam1 Coordonnées d'origine.
	 * @param poParam2 Coordonnées de la cible.
	 */
	public static calculateDistanceUsingCoordinatesKm(poParam1: ICoordinates, poParam2: ICoordinates): number {
		return GeolocationHelper.calculateDistanceBetweenCoordinatesKm(
			poParam1.latitude, poParam1.longitude, poParam2.latitude, poParam2.longitude);
	}

	/** Calcule la distance angulaire en radians depuis des coordonnées en degrés.
	 * @param pnSourceLatitude Latitude de la coordonnées d'origine (en degrés).
	 * @param pnSourceLongitude Longitude de la coordonnées d'origine (en degrés).
	 * @param pnTargetLatitude Latitude de la coordonnée cible (en degrés).
	 * @param pnTargetLongitude Longitude de la coordonnée cible (en degrés).
	 */
	private static calculateAngularDistanceRadian(pnSourceLatitude: number, pnSourceLongitude: number, pnTargetLatitude: number, pnTargetLongitude: number): number {
		return Math.acos(
			(this.getSinRadianFromDegree(pnSourceLatitude) * this.getSinRadianFromDegree(pnTargetLatitude)) +
			(this.getCosRadianFromDegree(pnSourceLatitude) * this.getCosRadianFromDegree(pnTargetLatitude) * this.getCosRadianFromDegree(pnTargetLongitude - pnSourceLongitude))
		);
	}

	/** Récupère le sinus en radian à partir d'une valeur en degrés.
	 * @param pnDegreeValue Valeur en degrés.
	 */
	private static getSinRadianFromDegree(pnDegreeValue: number): number {
		return Math.sin(ConvertHelper.degreeToRadian(pnDegreeValue));
	}

	/** Récupère le cosinus en radian à partir d'une valeur en degrés.
	 * @param pnDegreeValue Valeur en degrés.
	 */
	private static getCosRadianFromDegree(pnDegreeValue: number): number {
		return Math.cos(ConvertHelper.degreeToRadian(pnDegreeValue));
	}

	/** Détermine le niveau de zoom approprié.
	 * @param pnMaxDistLongitudeInKm La distance maximale en longitude entre les POI.
	 * @param pnMaxDistLatitudeInKm La distance maximale en latitude entre les POI.
	 *
	 * /!\ Ne pas override les autres paramètres.	 */
	private static getZoom(
		pnMaxDistLongitudeInKm: number,
		pnMaxDistLatitudeInKm: number,
		pnZoomLv: number = 5,
		pnCurrScreenWidthSizeInKm: number = 2048
	): number {
		const lnHalfCurrScreenSizeInKm: number = pnCurrScreenWidthSizeInKm * 0.5;
		return GeolocationHelper.isZoomLevelAppropriate(pnMaxDistLongitudeInKm, pnMaxDistLatitudeInKm, pnCurrScreenWidthSizeInKm) ?
			pnZoomLv : this.getZoom(pnMaxDistLongitudeInKm, pnMaxDistLatitudeInKm, pnZoomLv + 1, lnHalfCurrScreenSizeInKm);
	}

	/** Vérifie si le niveau de zoom actuel est approprié. */
	private static isZoomLevelAppropriate(pnDistOnX: number, pnDistOnY: number, pnScreenSize: number): boolean {
		return (pnDistOnX <= pnScreenSize && pnDistOnX >= pnScreenSize * 0.5) || (pnDistOnY * 2 <= pnScreenSize && pnDistOnY >= pnScreenSize * 0.25);
	}

	/** Calcule les coordonnées centrales pour la carte.
 * @param pnLen Le nombre de POI.
 * @param pnSumLat La somme des longitudes de tous les points d'intérêt.
 * @param pnSumLon La somme des latitudes de tous les points d'intérêt.
 * @returns Les coordonnées centrales.
 */
	private static getCenter(pnLen: number, pnSumLat: number, pnSumLon: number): ICoordinates {
		return { latitude: pnSumLat / pnLen, longitude: pnSumLon / pnLen };
	};

	/** Calcule les coordonnées des points appartenant à l'enveloppe convexe.
	 * @param paPoints  Tous les points du polygone.
	 * @returns Les coordonnées des points.
	 */
	public static getConvexHull(paPoints: ICoordinates[]): ICoordinates[] {
		const loGrahamScan = new GrahamScan();
		loGrahamScan.setPoints(
			paPoints.map((poCoords: ICoordinates) => { return [poCoords.latitude, poCoords.longitude]; })
		);

		return loGrahamScan.getHull().map((paCoord: number[]) => {
			return { latitude: paCoord[0], longitude: paCoord[1] };
		});
	}


	/** Obtient les options pour la vue de la carte.
	 * @param paPois Liste des POI à considérer.
	 * @returns Les options de démarrage de la carte.
	 */
	public static getStartMapOptions(paPois: ICoordinates[]): IStartMap | undefined {
		if (!ArrayHelper.hasElements(paPois))
			return undefined;

		let lnSumLon = 0;
		let lnSumLat = 0;

		paPois.forEach((poStartPoi: ICoordinates) => {
			lnSumLat += poStartPoi.latitude;
			lnSumLon += poStartPoi.longitude;
		});

		const lnMaxDistLongitude: number = GeolocationHelper.getMaxDistanceLongitudeKm(paPois);

		const lnMaxDistLatitude: number = GeolocationHelper.getMaxDistanceLatitudeKm(paPois);

		return {
			center: GeolocationHelper.getCenter(paPois.length, lnSumLat, lnSumLon),
			zoom: GeolocationHelper.getZoom(lnMaxDistLongitude, lnMaxDistLatitude)
		};
	}

	/** Retourne la distance en kilomètres entre les points ayant la longitude maximale et minimale dans la liste donnée.
	 * @param paPois - Un tableau d'objets de type ICoordinates représentant les coordonnées géographiques.
	 * @returns  La distance en kilomètres entre les longitudes extrêmes.
	 */
	public static getMaxDistanceLongitudeKm(paPois: ICoordinates[]): number {
		return GeolocationHelper.calculateDistanceBetweenCoordinatesKm(
			0, Math.max(...paPois.map((poCoords: ICoordinates): number => poCoords.longitude)),
			0, Math.min(...paPois.map((poCoords: ICoordinates): number => poCoords.longitude)));
	}

	/** Retourne la distance en kilomètres entre les points ayant la latitude maximale et minimale dans la liste donnée.
	 * @param paPois - Un tableau d'objets de type ICoordinates représentant les coordonnées géographiques.
	 * @returns La distance en kilomètres entre les latitudes extrêmes.
	 */
	public static getMaxDistanceLatitudeKm(paPois: ICoordinates[]): number {
		return GeolocationHelper.calculateDistanceBetweenCoordinatesKm(
			Math.max(...paPois.map((poCoords: ICoordinates): number => poCoords.latitude)), 0,
			Math.min(...paPois.map((poCoords: ICoordinates): number => poCoords.latitude)), 0);
	}

	//#endregion METHODS

}