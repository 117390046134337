import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { AppointmentService } from '@calaosoft/osapp/modules/tour/appointment/appointment.service';
import { ITour } from '@calaosoft/osapp/modules/tour/models/ITour';
import { TourService } from '@calaosoft/osapp/modules/tour/tour/tour.service';
import { EMPTY, Observable, from, of } from 'rxjs';
import { map, mergeMap, mergeMapTo, tap } from 'rxjs/operators';
import { ETourStatus } from '../models/ETourStatus';
import { IAppointment } from '../models/IAppointment';
import { IMerchandising } from '../models/IMerchandising';
import { ITourPathModels } from '../models/ITourPathModels';
import { IUnloading } from '../models/IUnloading';
import { MerchTourService } from '../services/merch-tour.service';

@Injectable({ providedIn: "root" })
export class TourResolver  {

	//#region FIELDS

	/** Mot utilisé pour parler de la tournée courante. */
	public static readonly C_CURRENT = "current";
	/** Url pour la page informant l'utilisateur qu'il n'a pas de tournée d'affectée. */
	public static readonly C_404 = "404";

	private static readonly C_LOG = "TR.RSL::";
	private static readonly C_NO_AFFECTED_USER_TOUR = "Vous n'avez aucune tournée d'affectée pour le moment.";

	//#endregion

	//#region METHODS

	constructor(private isvcTour: TourService, private isvcAppoint: AppointmentService, private ioRouter: Router) { }

	public resolve(poRoute: ActivatedRouteSnapshot): Observable<ITourPathModels | string> {
		const lsModelId: string = poRoute.params.tourId;
		const loParams: ITourPathModels = this.ioRouter.getCurrentNavigation()?.extras?.state as ITourPathModels || {} as any;

		if (lsModelId === TourResolver.C_CURRENT) // Récupère la tournée courante affectée à l'utilisateur.
			return this.handleCurrentTour();
		else if (lsModelId === TourResolver.C_404)
			return EMPTY;
		else if (lsModelId === MerchTourService.C_BLANK_MERCHANDISING_PAGE_URL)
			return of({ ...loParams, tour: undefined, appointments: [], isHistoryEnd: true } as ITourPathModels);
		else
			return this.getTourAndAppointments(lsModelId, loParams);
	}

	/** Gère le cas où la route contient comme id de tournée `current`. */
	private handleCurrentTour(): Observable<never> {
		return this.isvcTour.getCurrentTour<ETourStatus>()
			.pipe(
				mergeMap((poTour?: ITour<ETourStatus>) => {
					// Si pas de tournée courante/passée, on se dirige vers la page 404, sinon on se dirige vers la tournée courante/passée.
					const laUrlParts: string[] = [
						"tours",
						poTour ? poTour._id : TourResolver.C_404
					];

					const lsLogMessage = `${TourResolver.C_LOG}Redirection vers la tournée "${laUrlParts[1]}".`;
					poTour ? console.log(lsLogMessage) : console.error(lsLogMessage);

					// On ne peut pas passer activatedRoute dans les guards en tant que route relative https://stackoverflow.com/a/49822971.
					return from(this.ioRouter.navigate(
						laUrlParts,
						{ state: { message: TourResolver.C_NO_AFFECTED_USER_TOUR } as ITourPathModels } as NavigationExtras
					));
				}),
				mergeMap(_ => EMPTY)
			);
	}

	private getTourAndAppointments(psTourId: string, poParams: ITourPathModels): Observable<ITourPathModels> {
		// Chargement de la tournée, puis de ses rendez-vous associée.
		console.debug(`${TourResolver.C_LOG}Chargement de la tournée "${psTourId}".`);

		return this.isvcTour.getTour(psTourId)
			.pipe(
				tap(_ => console.debug(`${TourResolver.C_LOG}Chargement de la tournée "${psTourId}" terminé.`)),
				mergeMap((poTour?: ITour<ETourStatus>) => {
					if (poTour) {
						return this.isvcAppoint.getAppointments<ITour<ETourStatus>, IAppointment>(poTour)
							.pipe(
								tap((paAppoints: Array<IMerchandising | IUnloading>) => console.debug(`${TourResolver.C_LOG}Chargement des rendez-vous (${paAppoints.length}) de la tournée "${poTour._id}" terminé.`)),
								map((paAppoints: Array<IMerchandising | IUnloading>) => { return { ...poParams, tour: poTour, appointments: paAppoints } as ITourPathModels; })
							);
					}
					else { // Si la tournée n'a pas été trouvée, on route vers la page 404 dédiée.
						return from(this.ioRouter.navigate(
							["tours", TourResolver.C_404],
							{ state: { message: "La tournée n'a pu être trouvée." } as ITourPathModels } as NavigationExtras
						))
							.pipe(mergeMapTo(EMPTY));
					}
				})
			);
	}

	//#endregion

}