<osapp-header-modal title="FICHE ARTICLE" toolbarButtonsSlot="end"></osapp-header-modal>

<ng-container *ngIf="observableIsLoading.value$ | async; then loading else productItem"></ng-container>

<ng-template #loading>
	<ion-spinner></ion-spinner>
</ng-template>

<ng-template #productItem>
	<ng-container *ngIf="(observableItem.value$ | async) as item">
		<calao-dms-image *ngIf="(dmsIds$ | async) as dmsIds" [imageDmsIds]="dmsIds"></calao-dms-image>
		<div class="bottom-sheet-area">
			<ion-note>{{item.label}}</ion-note>
			<ion-label>{{itemId}}</ion-label>

			<ul>
				<li *ngFor="let category of item.categories">{{category.label}}</li>
			</ul>
		</div>
	</ng-container>
</ng-template>