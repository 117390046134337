import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertButton } from '@ionic/core';
import { Observable, defer, of } from 'rxjs';
import { map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { ConfigData } from '../../../../model/config/ConfigData';
import { IUiResponse } from '../../../../model/uiMessage/IUiResponse';
import { ApplicationService } from '../../../../services/application.service';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '../../../../services/platform.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { ImageDmsIds } from '../../../dms/model/image-dms-ids';
import { OsappApiHelper } from '../../../osapp-api/helpers/osapp-api.helper';
import { ISqlDocument } from '../../../sqlite/models/ISqlDocument';
import { SqlDatabaseProvider } from '../../../sqlite/models/SqlDatabaseProvider';
import { EUpdateStatus } from '../../../sqlite/models/eupdate-status';
import { RestartChoice } from '../../../sqlite/models/restart-choice';
import { SqlDataSource } from '../../../sqlite/models/sql-data-source';
import { SqlRequestResult } from '../../../sqlite/models/sql-request-result';
import { UpdateEvent } from '../../../sqlite/models/update-event';
import { SqlRequestService } from '../../../sqlite/services/sql-request.service';
import { SqlService } from '../../../sqlite/services/sql.service';
import { CATALOG_DATABASE_ID } from '../catalog.constants';
import { ICatalogVersionResponse } from '../models/ICatalogVersionResponse';
import { DmsArticleColor } from '../models/dms-article-color';
import { EMediaType } from '../models/emedia-type';
import { ICatalogProductItem } from '../models/i-catalog-product-item';
import { IBarcodeItem } from '../models/ibarcode-item';
import { ICatalogPickingArticle } from '../models/icatalog-picking-article';
import { ICategoryItem } from '../models/icategory-item';
import { IColorAndSizeItemInfo } from '../models/icolor-and-size-item-info';
import { IItemData } from '../models/iitem-data';
import { ILabel } from '../models/ilabel';
import { IPricedItem } from '../models/ipriced-item';
import { IColorIdsByItemId } from './models/i-item-id-and-color-ids';
import { ICatalogService } from './models/icatalog-service';

interface ISqliteMedia extends ISqlDocument {
	/** Identifiant de l'article associé. */
	itemId: string;
	/** Identifiant de l'image. */
	mediaId: string
	/** Type de media. */
	type: string;
	/** Identifiant de la couleur. */
	colorId?: string;
}

interface IVariantId { variantId: string; }

interface IItemId { itemId: string; }

interface IItemIdAndVariantId extends IItemId, IVariantId { }

interface IBarcode { barcode: string; }

interface IBarcodeAndItemId extends IBarcode, IItemId { }

interface IBarcodeMediaId {
	readonly barcode: string;
	readonly mediaId: string;
}

interface IDmsArticleColor {
	readonly itemId: string;
	readonly id?: string | undefined;
	readonly label?: string | undefined;
	readonly dmsId?: string | undefined;
	readonly thumbnailDmsId?: string | undefined;
}

interface IMissingColorResponse {
	itemId: string;
	colorLabel: string;
}

interface IColorResponse {
	id: string;
	label: string;
}

interface IGetItemFromIdResult {
	itemId: string;
	itemLabel?: string;
	photoDmsId?: string;
	categoryId?: string;
	categoryLabel?: string;
	categoryType?: string;
}

@Injectable()
export class CatalogService implements ICatalogService {

	//#region FIELDS

	/** Identifiant du service pour les logs. */
	private static readonly C_LOG_ID = "CTLG.S::";
	/** Nom de la table des articles. */
	private static readonly C_ITEM_TABLE_NAME = "Item";
	/** Nom de la table des media. */
	private static readonly C_MEDIA_TABLE_NAME = "Media";
	/** Map qui met en cache les informations d'un article en fonction de son code-barres. */
	private static readonly C_BARCODE_ITEM_BY_BARCODE = new Map<string, IBarcodeItem>();
	private static readonly C_DB_PREFIX = `catalog`;

	private readonly msCatalogVersionUrl: string;
	private readonly mbIsMobile: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioHttpClient: HttpClient,
		protected readonly isvcSql: SqlService,
		private readonly isvcSqlRequest: SqlRequestService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcProvider: SqlDatabaseProvider,
		psvcPlatform: PlatformService
	) {
		this.msCatalogVersionUrl = `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/common/entities/items/catalog/last`;
		this.mbIsMobile = psvcPlatform.isMobileApp;

		this.initAsync();
	}

	//#region Requests

	/** Exécute et retourne le résultat d'une requête.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param psRequest Requête à exécuter.
	 * @param paParams Valeur à satisfaire pour filtrer les résultats (where).
	 */
	private requestAsync<T>(psDatabaseId: string, psRequest: string, paParams: Array<string | number> = []): Promise<SqlRequestResult<T>> {
		const loSqlDatabaseSource: SqlDataSource | undefined = this.isvcSql.getOpenedDataSource(psDatabaseId);

		if (!loSqlDatabaseSource) {
			console.error(`${CatalogService.C_LOG_ID}The SQL data source of database '${psDatabaseId}' is missing, can not exec request : ${psRequest}`);
			return Promise.reject(new Error(`Query execution failed because the SQL data source of database '${psDatabaseId}' is missing.`));
		}
		else
			return this.isvcSql.requestAsync<T>(loSqlDatabaseSource, psRequest, paParams, CatalogService.C_DB_PREFIX);
	}

	/** @implements */
	public getAllItems<T extends ISqlDocument = ISqlDocument>(): Observable<T[]> {
		return defer(() => this.requestAsync<T>(CATALOG_DATABASE_ID, this.isvcSqlRequest.selectAllFromTableRequest(CatalogService.C_ITEM_TABLE_NAME), undefined))
			.pipe(
				tap((poResult: SqlRequestResult<T>) => console.info(`${CatalogService.C_LOG_ID}Durée récupération tous documents bdd '${CATALOG_DATABASE_ID}' table '${CatalogService.C_ITEM_TABLE_NAME}' = ${poResult.time}ms`)),
				map((poResult: SqlRequestResult<T>) => poResult.results)
			);
	}

	/** @implements */
	public getBarcodeItemFromBarcode(psBarcode: string): Observable<IBarcodeItem | undefined> {
		if (StringHelper.isBlank(psBarcode))
			return of(undefined);
		else {
			const laBarcodes: string[] = [psBarcode]; // Tableau contenant le code-barres concerné par la requête.

			return defer(() => this.requestAsync<IBarcodeItem>(
				CATALOG_DATABASE_ID,
				this.getBarcodeItemByBarcodeRequest(laBarcodes),
				laBarcodes
			))
				.pipe(
					tap((poResult: SqlRequestResult<IBarcodeItem>) => console.info(`${CatalogService.C_LOG_ID}Get item data from barcode in ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IBarcodeItem>): IBarcodeItem | undefined => {
						if (poResult.hasResults())
							return { ...poResult.getFirstResult()!, code: psBarcode };
						else
							return undefined;
					})
				);
		}
	}

	/** @implements */
	public getCategoryItemAsync(psBarcode: string, paCategoryIds: string[]): Promise<ICategoryItem | undefined> {
		if (StringHelper.isBlank(psBarcode) || !paCategoryIds)
			return Promise.resolve(undefined);
		else {
			const laBarcodes: string[] = [psBarcode]; // Tableau contenant le code-barres concerné par la requête.

			return defer(() => this.requestAsync<ICategoryItem>(
				CATALOG_DATABASE_ID,
				this.getCategoryItemRequest(laBarcodes, paCategoryIds),
				laBarcodes.concat(paCategoryIds)
			))
				.pipe(
					tap((poResult: SqlRequestResult<ICategoryItem>) => console.info(`${CatalogService.C_LOG_ID}Get item data from barcode in ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<ICategoryItem>): ICategoryItem | undefined => {
						if (poResult.hasResults())
							return { ...poResult.getFirstResult()!, code: psBarcode };
						else
							return undefined;
					})
				).toPromise();
		}
	}

	/** @implements */
	public getBarcodeItemByBarcode(paBarcodes: string[]): Observable<Map<string, IBarcodeItem>> {
		if (!ArrayHelper.hasElements(paBarcodes))
			return of(new Map());
		else {
			const laUniqueBarcodes: string[] = ArrayHelper.unique(paBarcodes);
			const laMissingBarcodes: string[] = [];
			const laCachedBarcodeItems: IBarcodeItem[] = [];

			// On vérifie le cache pour ne requêter que ce dont on a pas encore les informations.
			if (CatalogService.C_BARCODE_ITEM_BY_BARCODE.size > 0) {
				laUniqueBarcodes.forEach((psBarcode: string) => {
					const loItem: IBarcodeItem | undefined = CatalogService.C_BARCODE_ITEM_BY_BARCODE.get(psBarcode);
					loItem ? laCachedBarcodeItems.push(loItem) : laMissingBarcodes.push(psBarcode);
				});
			}
			else
				laMissingBarcodes.push(...laUniqueBarcodes);

			return defer(() => ArrayHelper.hasElements(laMissingBarcodes) ?
				this.execGetBarcodeItemByBarcodeRequest(laMissingBarcodes, laCachedBarcodeItems) : of(laCachedBarcodeItems)
			)
				.pipe(map((paResults: IBarcodeItem[]) => this.createBarcodeItemByBarcodeMap(paResults, paBarcodes)));
		}
	}

	private execGetBarcodeItemByBarcodeRequest(paMissingBarcodes: string[], paCachedBarcodeItems: IBarcodeItem[]): Observable<IBarcodeItem[]> {
		return defer(() => this.requestAsync<IBarcodeItem>(CATALOG_DATABASE_ID, this.getBarcodeItemByBarcodeRequest(paMissingBarcodes), paMissingBarcodes))
			.pipe(
				tap((poResult: SqlRequestResult<IBarcodeItem>) => {
					console.info(`${CatalogService.C_LOG_ID}Get item data from barcode in ${poResult.time}ms`);
					this.updateBarcodeItemByBarcodeCache(poResult.results);
				}),
				map((poResult: SqlRequestResult<IBarcodeItem>) => poResult.results.concat(paCachedBarcodeItems))
			);
	}

	private getBarcodeItemByBarcodeRequest(paUniqueBarcodes: string[]): string {
		return `
			SELECT i.id as itemId, Tariff.priceType, Tariff.barcode as code, Tariff.priceValue, i.label, s.id as sizeId, s.label as sizeLabel, c.id as colorId, c.label as colorLabel, m.mediaId as thumbnailDmsId, mm.mediaId as colorDmsId, mmm.mediaId as photoDmsId
			FROM Tariff
			INNER JOIN Variant v ON v.id = Tariff.variantId
			INNER JOIN Item i ON i.id = v.itemId
			INNER JOIN Size s ON s.id = v.sizeId
			INNER JOIN Color c ON c.id = v.colorId
			LEFT JOIN Media m ON m.itemId = i.id AND m.colorId = v.colorId AND m.type = "thumbnail"
			LEFT JOIN Media mm ON mm.itemId = i.id AND mm.colorId = v.colorId AND mm.type = "color"
			LEFT JOIN Media mmm ON mmm.itemId = i.id AND mmm.colorId = v.colorId AND mmm.type = "photo"
			WHERE Tariff.barcode ${this.isvcSqlRequest.getInRequest(paUniqueBarcodes.length)}
		`;
	}

	private getCategoryItemRequest(paUniqueBarcodes: string[], paUniqueCategories: string[]): string {
		return `
			SELECT *
			FROM Tariff
			LEFT JOIN Variant ON Tariff.variantId = Variant.id
			LEFT JOIN Item_ItemCategory ON Variant.itemId = Item_ItemCategory.itemId
			WHERE Tariff.barcode ${this.isvcSqlRequest.getInRequest(paUniqueBarcodes.length)}
			AND Item_ItemCategory.categoryId ${this.isvcSqlRequest.getInRequest(paUniqueCategories.length)}
		`;
	}

	private createBarcodeItemByBarcodeMap(paResults: IBarcodeItem[], paBarcodes: string[]): Map<string, IBarcodeItem> {
		const loMap = new Map<string, IBarcodeItem>();

		paBarcodes.forEach((psBarcode: string) => {
			if (!loMap.has(psBarcode)) {
				const loBarcodeItem: IBarcodeItem | undefined = paResults.find((poItem: IBarcodeItem) => poItem.code === psBarcode);

				if (loBarcodeItem)
					loMap.set(psBarcode, loBarcodeItem);
				else
					console.error(`${CatalogService.C_LOG_ID}Barcode '${psBarcode}' not found in catalog.`);
			}
		});

		return loMap;
	}

	/** Met à jour la map contenant les informations d'un article en fonction de son code-barres.
	 * @param paItems Tableau des éléments à mettre dans le cache s'ils ne sont pas déjà présents.
	 */
	private updateBarcodeItemByBarcodeCache(paItems: IBarcodeItem[]): void {
		paItems.forEach((poItem: IBarcodeItem) => {
			if (!CatalogService.C_BARCODE_ITEM_BY_BARCODE.has(poItem.code))
				CatalogService.C_BARCODE_ITEM_BY_BARCODE.set(poItem.code, poItem);
		});
	}

	/** Supprime le cache pour les requêtes de récupération d'informations d'article en fonction de leur code-barres. */
	public clearBarcodeItemByBarcodeCache(): void {
		CatalogService.C_BARCODE_ITEM_BY_BARCODE.clear();
	}

	/** @implements */
	public getItemDataFromVariantId(psVariantId: string): Observable<IItemData | undefined> {
		if (StringHelper.isBlank(psVariantId))
			return of(undefined);
		else {
			const lsRequest = `
				SELECT i.id as itemId, i.label, s.id as sizeId, s.label as sizeLabel, c.id as colorId, c.label as colorLabel, m.mediaId as thumbnailDmsId, mm.mediaId as colorDmsId, mmm.mediaId as photoDmsId
				FROM Variant v
					INNER JOIN Item i ON i.id = v.itemId
					INNER JOIN Size s ON s.id = v.sizeId
					INNER JOIN Color c ON c.id = v.colorId
				LEFT JOIN Media m ON m.itemId = i.id AND m.colorId = v.colorId AND m.type = "thumbnail"
				LEFT JOIN Media mm ON mm.itemId = i.id AND mm.colorId = v.colorId AND mm.type = "color"
				LEFT JOIN Media mmm ON mmm.itemId = i.id AND mmm.colorId = v.colorId AND mmm.type = "photo"
				WHERE v.id = ?
			 `;

			return defer(() => this.requestAsync<IItemData>(CATALOG_DATABASE_ID, lsRequest, [psVariantId]))
				.pipe(
					tap((poResult: SqlRequestResult<IItemData>) => console.info(`${CatalogService.C_LOG_ID}Get item data from variantId in ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IItemData>) => poResult.getFirstResult())
				);
		}
	}

	public getItemFromId(psItemId: string): Observable<ICatalogProductItem | undefined> {
		if (StringHelper.isBlank(psItemId))
			return of(undefined);
		else {
			const lsRequest = `
				SELECT i.id as itemId, i.label as itemLabel, mmm.mediaId as photoDmsId, iic.type, ic.id as categoryId, ic.label as categoryLabel, ic.type as categoryType
				FROM Item i
				LEFT JOIN
						(
								SELECT m1.itemId, m1.mediaId
								FROM Media m1
								WHERE m1.itemId = ?
						AND m1.type = 'photo'
						LIMIT 1
						) mmm
				LEFT JOIN Item_ItemCategory iic ON iic.itemId = ?
				LEFT JOIN ItemCategory ic ON ic.id = iic.categoryId
				WHERE i.id = ?
			 `;

			return defer(() => this.requestAsync<IGetItemFromIdResult>(CATALOG_DATABASE_ID, lsRequest, [psItemId, psItemId, psItemId]))
				.pipe(
					tap((poResult: SqlRequestResult<IGetItemFromIdResult>) => console.info(`${CatalogService.C_LOG_ID}Get item data from itemId in ${poResult.time}ms`)),
					map((poRequestResult: SqlRequestResult<IGetItemFromIdResult>) => {
						const loFirstResult = poRequestResult.getFirstResult();

						if (!loFirstResult)
							return undefined;

						const loItem: ICatalogProductItem = { itemId: loFirstResult.itemId, label: loFirstResult.itemLabel, photoGedId: loFirstResult.photoDmsId, categories: [] };
						poRequestResult.results.forEach((poItem: IGetItemFromIdResult) => {
							if (poItem.categoryId && poItem.categoryLabel && poItem.categoryType)
								loItem.categories.push({ id: poItem.categoryId, label: poItem.categoryLabel, type: poItem.categoryType });
						});

						return loItem;
					})
				);
		}
	}

	/** @implements */
	public getMediasIdFromItemId(psItemId: string, psColorId: string | undefined, paMediaTypes: EMediaType[] = [EMediaType.thumbnail]): Observable<Map<EMediaType, string> | undefined> {
		if (StringHelper.isBlank(psItemId))
			return of(undefined);
		else {
			const lsRequest = `
				SELECT *
				FROM ${CatalogService.C_MEDIA_TABLE_NAME}
				WHERE itemId = ?
				AND type IN (${paMediaTypes.map((poMedia: EMediaType) => `"${poMedia}"`).join(",")})
			`;

			return defer(() => this.requestAsync<ISqliteMedia>(CATALOG_DATABASE_ID, lsRequest, [psItemId]))
				.pipe(
					tap((poResult: SqlRequestResult<ISqliteMedia>) => console.info(`${CatalogService.C_LOG_ID}Récupération id media en ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<ISqliteMedia>) => {
						let laFilteredMediaByColor: ISqliteMedia[] = poResult.results;

						if (psColorId)
							laFilteredMediaByColor = poResult.results.filter((poMedia: ISqliteMedia) => poMedia.colorId === psColorId);

						return laFilteredMediaByColor;
					}),
					map((paResults: ISqliteMedia[]) => {
						const loMediaByType = new Map<EMediaType, string>();

						paMediaTypes.forEach((poMediaType: EMediaType) => {
							const lsMediaId: string | undefined = paResults.find((poMedia: ISqliteMedia) => poMedia.type === poMediaType)?.mediaId;
							if (lsMediaId)
								loMediaByType.set(poMediaType, lsMediaId);
						});

						return loMediaByType;
					})
				);
		}
	}

	public getImageDmsIdsFromItemId(psItemId: string, paMediaType: EMediaType): Observable<ImageDmsIds | undefined> {
		if (StringHelper.isBlank(psItemId))
			return of(undefined);
		else {
			const lsRequest = `
				SELECT *
				FROM ${CatalogService.C_MEDIA_TABLE_NAME}
				WHERE itemId = ?
				AND type = ?
			`;

			return defer(() => this.requestAsync<ISqliteMedia>(CATALOG_DATABASE_ID, lsRequest, [psItemId, paMediaType]))
				.pipe(
					tap((poResult: SqlRequestResult<ISqliteMedia>) => console.info(`${CatalogService.C_LOG_ID}Récupération ids media depuis itemId en ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<ISqliteMedia>): ImageDmsIds | undefined => {
						if (!poResult.hasResults())
							return undefined;
						else {
							const lsFirstId: string = poResult.results.shift()!.mediaId;
							const laOtherIds: Array<string> = poResult.results.map((poMedia: ISqliteMedia) => poMedia.mediaId);
							return new ImageDmsIds(lsFirstId, laOtherIds);
						}
					})
				);
		}
	}

	/** @implements */
	public getMediaIdByBarcode(paBarcodes: string[], psMediaType: string = EMediaType.thumbnail): Observable<Map<string, string>> {
		if (!ArrayHelper.hasElements(paBarcodes))
			return of(new Map<string, string>());
		else {
			const laUniqueBarcodes: string[] = ArrayHelper.unique(paBarcodes);

			return defer(() => this.requestAsync(CATALOG_DATABASE_ID, this.getMediaIdByBarcodeRequest(laUniqueBarcodes, psMediaType), laUniqueBarcodes))
				.pipe(
					tap((poResult: SqlRequestResult<IBarcodeMediaId>) => console.info(`${CatalogService.C_LOG_ID}Get mediaId by barcode in ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IBarcodeMediaId>) => new Map<string, string>(poResult.results.map((poItem: IBarcodeMediaId) => [poItem.barcode, poItem.mediaId])))
				);
		}
	}

	/** Requête pour récupérer les médias des codes-barres passés en paramètre dans le tableau.
	 * @param paItemIds Tableau des codes-barres des articles à récupérer.
	 * @param psMediaType Type du média à récupérer.
	 */
	private getMediaIdByBarcodeRequest(paBarcodes: string[], psMediaType: string): string {
		return `
			SELECT t.barcode, m.mediaId
			FROM Tariff t, Variant v, Media m
			WHERE t.barcode ${this.isvcSqlRequest.getInRequest(paBarcodes.length)}
			AND t.variantId = v.id
			AND v.colorId = m.colorId
			AND v.itemId = m.itemId
			AND m.type = '${psMediaType}'
		`;
	}

	/** @implements */
	public getColorAndSizeItemInfoFromBarcode(psBarcode: string): Observable<IColorAndSizeItemInfo | undefined> {
		if (StringHelper.isBlank(psBarcode))
			return of(undefined);
		else {
			const lsRequest = `
				SELECT i.id as itemId, s.id as sizeId, s.label as sizeLabel, c.id as colorId, c.label as colorLabel
				FROM Item i, Size s, Color c, Tariff t, Variant v
				WHERE t.barcode = ?
				AND v.itemId = i.id
				AND v.sizeId = s.id
				AND v.colorId = c.id
				AND v.id = t.variantId
			`;

			return this.execGetColorAndSizeItemInfoRequest(lsRequest, psBarcode)
				.pipe(map((poResult: SqlRequestResult<IColorAndSizeItemInfo>) => poResult.getFirstResult()));
		}
	}

	/** Exécute une requête pour récupérer un tableau d'objets contenant l'identifiant ainsi que les libellés de la couleur et de la taille d'un article,
	 * tableau vide si aucun élément trouvé.
	 * @param psRequest Requête à exécuter.
	 * @param psDynamicParams Paramètre dynamique permettant de réaliser la requête.
	 */
	private execGetColorAndSizeItemInfoRequest(psRequest: string, psDynamicParams: string): Observable<SqlRequestResult<IColorAndSizeItemInfo>> {
		return defer(() => this.requestAsync<IColorAndSizeItemInfo>(CATALOG_DATABASE_ID, psRequest, [psDynamicParams]))
			.pipe(tap((poResult: SqlRequestResult<IColorAndSizeItemInfo>) => console.info(`${CatalogService.C_LOG_ID}Récupération couleur/taille/id article en ${poResult}ms`)));
	}

	/** @implements */
	public getColorAndSizeItemInfoFromItemId(psItemId: string): Observable<IColorAndSizeItemInfo[]> {
		if (StringHelper.isBlank(psItemId))
			return of([]);
		else {
			const lsRequest = `
				SELECT i.id as itemId, c.id as colorId, c.label as colorLabel, s.id as sizeId, s.label as sizeLabel
				FROM Item i, Variant v, Color c, Size s
				WHERE i.id = ?
				AND v.itemId = i.id
				AND v.colorId = c.id
				AND v.sizeId = s.id
			`;

			return this.execGetColorAndSizeItemInfoRequest(lsRequest, psItemId).pipe(
				map((poResult: SqlRequestResult<IColorAndSizeItemInfo>) => poResult.results)
			);
		}
	}

	/** @implements */
	public getPricedItems(psItemId: string, psColorId: string, psSizeId: string): Observable<IPricedItem[]> {
		if (StringHelper.isBlank(psItemId))
			return of([]);
		else {
			const lsRequest = `
				SELECT i.id as itemId, i.label, t.priceType, t.priceValue
				FROM Item i, Variant v, Color c, Size s, Tariff t
				WHERE i.id = ?
				AND v.itemId = i.id
				AND c.id = ?
				AND v.colorId = c.id
				AND s.id = ?
				AND v.sizeId = s.id
				AND t.variantId = v.id
				ORDER BY date(t.startDate)
			`;

			return defer(() => this.requestAsync<IPricedItem>(CATALOG_DATABASE_ID, lsRequest, [psItemId, psColorId, psSizeId]))
				.pipe(
					tap((poResult: SqlRequestResult<IPricedItem>) => console.info(`${CatalogService.C_LOG_ID}Récupération infos article '${psItemId}' en ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IPricedItem>) => poResult.results)
				);
		}
	}

	/** @implements */
	public getPricedItemFromBarcode(psBarcode: string): Observable<IPricedItem | undefined> {
		if (StringHelper.isBlank(psBarcode))
			return of(undefined);
		else {
			const lsRequest = `
			SELECT i.id as itemId, i.label, t.priceType, t.priceValue
			FROM Item i, Tariff t, Variant v
			WHERE t.barcode = ?
			AND t.variantId = v.id
			AND v.itemId = i.id
		`;

			return this.execGetPricedItem(lsRequest, [psBarcode]);
		}
	}

	/** Exécute la requête pour récupérer les informations d'un article, `undefined` si non trouvées.
	 * @param psRequest Requête à exécuter pour récupérer les informations d'un article.
	 * @param paParams Tableau des paramètres dynamiques de la requête.
	 */
	private execGetPricedItem(psRequest: string, paParams: string[]): Observable<IPricedItem | undefined> {
		return defer(() => this.requestAsync<IPricedItem>(CATALOG_DATABASE_ID, psRequest, paParams))
			.pipe(
				tap((poResult: SqlRequestResult<IPricedItem>) => console.info(`${CatalogService.C_LOG_ID}Récupération infos article '${paParams[0]}' en ${poResult.time}ms`)),
				map((poResult: SqlRequestResult<IPricedItem>) => poResult.getFirstResult())
			);
	}

	/** @implements */
	public getILabelFromItemId(psItemId: string): Observable<ILabel | undefined> {
		if (StringHelper.isBlank(psItemId))
			return of(undefined);
		else {
			const lsRequest = `
			SELECT i.label
			FROM Item i
			WHERE i.id = ?
		`;
			return defer(() => this.requestAsync<ILabel>(CATALOG_DATABASE_ID, lsRequest, [psItemId]))
				.pipe(
					tap((poResult: SqlRequestResult<ILabel>) => console.info(`${CatalogService.C_LOG_ID}Récupération libellé article '${psItemId}' en ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<ILabel>) => poResult.getFirstResult())
				);
		}
	}

	/** @implements */
	public getItemIdByBarcode(paBarcodes: string[]): Observable<Map<string, string>> {
		const laBarcodes: string[] = ArrayHelper.unique(paBarcodes);

		if (!ArrayHelper.hasElements(laBarcodes))
			return of(new Map<string, string>());
		else {
			const lsRequest = `
					SELECT v.itemId, t.barcode
					FROM Tariff t, Variant v
					WHERE t.barcode ${this.isvcSqlRequest.getInRequest(laBarcodes.length)}
					AND t.variantId = v.id
				`;

			return defer(() => this.requestAsync<IBarcodeAndItemId>(CATALOG_DATABASE_ID, lsRequest, laBarcodes))
				.pipe(
					tap((poResult: SqlRequestResult<IBarcodeAndItemId>) => console.debug(`${CatalogService.C_LOG_ID}Récupération itemIds depuis code-barres en ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IBarcodeAndItemId>) =>
						new Map<string, string>(poResult.results.map((poItem: IBarcodeAndItemId) => [poItem.barcode, poItem.itemId]))
					)
				);
		}
	}

	/** @implements */
	public getItemIdFromBarcode(psBarcode: string): Observable<string | undefined> {
		if (StringHelper.isBlank(psBarcode))
			return of(undefined);
		else {
			const lsRequest = `
					SELECT v.itemId, t.barcode
					FROM Tariff t, Variant v
					WHERE t.barcode = ?
					AND t.variantId = v.id
				`;

			return defer(() => this.requestAsync<IBarcodeAndItemId>(CATALOG_DATABASE_ID, lsRequest, [psBarcode]))
				.pipe(
					tap((poResult: SqlRequestResult<IBarcodeAndItemId>) => console.debug(`${CatalogService.C_LOG_ID}Récupération itemId depuis code-barres en ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IBarcodeAndItemId>) => poResult.getFirstResult()?.itemId)
				);
		}
	}

	/** @implements */
	public getVariantId(psItemId: string, psColorId: string, psSizeId: string): Observable<string | undefined> {
		if (StringHelper.isBlank(psItemId))
			return of(undefined);
		else {
			return defer(() => this.requestAsync<IVariantId>(
				CATALOG_DATABASE_ID,
				`SELECT id as variantId FROM Variant WHERE itemId = ? AND colorId = ? AND sizeId = ?`,
				[psItemId, psColorId, psSizeId]
			))
				.pipe(
					tap((poResult: SqlRequestResult<IVariantId>) => console.debug(`${CatalogService.C_LOG_ID}Récupération variantId en ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IVariantId>) => poResult.getFirstResult()?.variantId)
				);
		}
	}

	/** @implements */
	public getItemIdByVariantId(paVariantIds: string[]): Observable<Map<string, string>> {
		const loMapResult = new Map<string, string>();

		if (!ArrayHelper.hasElements(paVariantIds))
			return of(loMapResult);
		else {
			return defer(() => this.requestAsync<IItemIdAndVariantId>(
				CATALOG_DATABASE_ID,
				`SELECT itemId, id as variantId FROM Variant WHERE id ${this.isvcSqlRequest.getInRequest(paVariantIds.length)}`,
				paVariantIds
			))
				.pipe(
					tap((poResult: SqlRequestResult<IItemIdAndVariantId>) => console.debug(`${CatalogService.C_LOG_ID}Récupération itemIds depuis variantIds en ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IItemIdAndVariantId>) => {
						poResult.results.forEach((poItem: IItemIdAndVariantId) => {
							if (!loMapResult.has(poItem.variantId))
								loMapResult.set(poItem.variantId, poItem.itemId);
						});

						return loMapResult;
					})
				);
		}
	}

	/** @implements */
	public getBarcodesFromItemId(psItemId?: string): Observable<string[]> {
		if (StringHelper.isBlank(psItemId))
			return of([]);
		else {
			return defer(() => this.requestAsync<IBarcode>(
				CATALOG_DATABASE_ID,
				`SELECT t.barcode FROM Variant v, Tariff t WHERE v.itemId = ? AND v.id = t.variantId`,
				[psItemId]
			))
				.pipe(
					tap((poResult: SqlRequestResult<IBarcode>) => console.debug(`${CatalogService.C_LOG_ID}Barcodes got in ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IBarcode>) => poResult.results.map((poBarcode: IBarcode) => poBarcode.barcode))
				);
		}
	}

	/** @implements */
	public getDmsArticleColorsByItemId(paItemIds: string[]): Observable<Map<string, DmsArticleColor[]>> {
		if (!ArrayHelper.hasElements(paItemIds))
			return of(new Map());
		else {
			return defer(() => this.requestAsync<IDmsArticleColor>(
				CATALOG_DATABASE_ID,
				this.getArticleColorsByItemIdRequest(paItemIds),
				paItemIds
			))
				.pipe(
					tap((poResult: SqlRequestResult<IDmsArticleColor>) => console.debug(`${CatalogService.C_LOG_ID}Article colors by item id got in ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<IDmsArticleColor>) => {
						const loMap = new Map<string, DmsArticleColor[]>();
						const laDmsArticleColors: DmsArticleColor[] = this.createDmsArticleColors(poResult.results);

						paItemIds.forEach((psItemId: string) => {
							loMap.set(
								psItemId,
								laDmsArticleColors.filter((poInstance: DmsArticleColor) => poInstance.itemId === psItemId)
							);
						});

						return loMap;
					})
				);
		}
	}

	private getArticleColorsByItemIdRequest(paItemIds: string[]): string {
		return `
			SELECT m.itemId, c.id, c.label, m.mediaId as dmsId, mm.mediaId as thumbnailDmsId
			FROM Media m, Color c
			LEFT JOIN Media mm ON mm.itemId = m.itemId AND mm.colorId = c.id AND mm.type = "thumbnail"
			WHERE m.itemId ${this.isvcSqlRequest.getInRequest(paItemIds.length)}
			AND m.type = "color"
			AND m.colorId = c.id
		`;
	}

	private createDmsArticleColors(paItems: IDmsArticleColor[]): DmsArticleColor[] {
		return paItems.map((poItem: IDmsArticleColor) => new DmsArticleColor(poItem.itemId, poItem.id, poItem.label, poItem.dmsId, poItem.thumbnailDmsId));
	}

	/** Récupère les informations de prélèvement d'un article, `undefined` si non trouvé.
	 * @param psItemId Identifiant de l'article dont il faut récupérer les informations.
	 */
	public getPickingItemFromItemId$(psItemId: string): Observable<ICatalogPickingArticle | undefined> {
		if (StringHelper.isBlank(psItemId))
			return of(undefined);
		else {
			const lsRequest = `
				SELECT i.id as itemId, t.priceType, t.priceValue, i.label, m.mediaId as imageGedId
				FROM Item i
					LEFT JOIN Variant v ON i.id = v.itemId
					LEFT JOIN Tariff t ON v.id = t.variantId
					LEFT JOIN Media m ON m.itemId = i.id AND m.type = "thumbnail"
					WHERE i.id = ?
				LIMIT 1
			`;

			return defer(() => this.requestAsync<ICatalogPickingArticle>(CATALOG_DATABASE_ID, lsRequest, [psItemId]))
				.pipe(
					tap((poResult: SqlRequestResult<ICatalogPickingArticle>) => console.info(`${CatalogService.C_LOG_ID}Get picking item data from itemId in ${poResult.time}ms`)),
					map((poResult: SqlRequestResult<ICatalogPickingArticle>): ICatalogPickingArticle | undefined => poResult.getFirstResult())
				);
		}
	}

	/** Parmis la liste des articles avec la couleur passée en paramètre, filtre ceux qui ont le code-barres.
	 * @param paDesiredColors Liste des articles et des couleurs qu'il faut prendre en compte.
	 * @param paConsideredBarcodes Ignore les couleurs si leur code-barres est dans cette liste.
	 */
	public getMissingColors(paDesiredColors: IColorIdsByItemId[], paConsideredBarcodes: string[]): Promise<IColorIdsByItemId[]> {
		if (!ArrayHelper.hasElements(paDesiredColors))
			return Promise.resolve([]);
		else {
			return this.requestAsync<IMissingColorResponse>(
				CATALOG_DATABASE_ID,
				this.getMissingColorsRequest(paDesiredColors, paConsideredBarcodes.length),
				paDesiredColors.map((poItem: IColorIdsByItemId) => [poItem.itemId, ...poItem.colorLabels]).concat(paConsideredBarcodes).flat()
			)
				.then((poResult: SqlRequestResult<IMissingColorResponse>) => {
					console.debug(`${CatalogService.C_LOG_ID}Get missing colors in ${poResult.time}ms`);
					return this.formatMissingColorResponse(poResult.results);
				});
		}
	}

	private formatMissingColorResponse(paRequestResult: IMissingColorResponse[]): IColorIdsByItemId[] {
		const laMissingColors: IColorIdsByItemId[] = [];

		paRequestResult.forEach((poValue: IMissingColorResponse) => {
			if (laMissingColors.length === 0 || laMissingColors[laMissingColors.length - 1].itemId !== poValue.itemId) {
				// Si c'est notre premier élément ou si l'item id du dernier élément est différent alors on ajoute un nouvel élément.
				laMissingColors.push({ itemId: poValue.itemId, colorLabels: [poValue.colorLabel] });
			} else {
				// Si c'est le même item id alors on ajoute la couleur au dernier élément.
				laMissingColors[laMissingColors.length - 1].colorLabels.push(poValue.colorLabel);
			}
		});

		return laMissingColors;
	}

	private getMissingColorsRequest(paItemsAndColorIds: IColorIdsByItemId[], psBarcodesArrayLength: number): string {
		const lsWhereCondition = `(${paItemsAndColorIds.map(
			(poItemAndColorIds: IColorIdsByItemId) => `Variant.itemId = ? AND (Variant.colorId ${this.isvcSqlRequest.getInRequest(poItemAndColorIds.colorLabels.length)})`
		).join(") OR (")})`;

		return `
			SELECT Variant.itemId, Color.label as colorLabel
			FROM Tariff
			LEFT JOIN Variant ON Tariff.variantId = Variant.id
			LEFT JOIN Color ON Color.id = Variant.colorId
			WHERE (${lsWhereCondition})
				AND NOT EXISTS (
						SELECT 1
						FROM Tariff t
						JOIN Variant v ON t.variantId = v.id
						WHERE t.barcode ${this.isvcSqlRequest.getInRequest(psBarcodesArrayLength)}
						AND (v.itemId = Variant.itemId
						AND v.colorId = Variant.colorId)
				)
			GROUP BY Variant.itemId, Variant.colorId
		`;
	}

	//#endregion Requests

	//#region Managing

	/** Initialise la base de données. */
	private async initAsync(): Promise<void> {
		// On charge la dernière version du catalogue disponible.
		const loLastDatasource: SqlDataSource | undefined = await this.isvcProvider.getLastReadyAsync(CATALOG_DATABASE_ID);

		if (loLastDatasource)
			return this.isvcSql.openDatabaseAsync(loLastDatasource);
	}

	public getOpenedDataSource(): SqlDataSource | undefined {
		return this.isvcSql.getOpenedDataSource(CATALOG_DATABASE_ID);
	}

	public getLastReadyVersionAsync(): Promise<number | undefined> {
		return this.isvcProvider.getLastReadyAsync(CATALOG_DATABASE_ID).then((poDatasource?: SqlDataSource) => poDatasource ? poDatasource.version : undefined);
	}

	/** @implements */
	public getLastVersionData(): Observable<ICatalogVersionResponse> {
		return this.ioHttpClient.get(this.msCatalogVersionUrl,
			{
				headers: new HttpHeaders({
					appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
					token: ConfigData.authentication.token!,
					"api-key": ConfigData.environment.API_KEY!,
					accept: "application/json"
				}),
				observe: "body",
				responseType: "json"
			}
		) as Observable<ICatalogVersionResponse>;
	}

	/** @implements */
	public getCurrentCatalogVersionAsync(): Promise<number> {
		return this.isvcSql.getLastVersionDownloadedAsync(CATALOG_DATABASE_ID);
	}

	/** @implements */
	public removeCatalog(): Promise<void> {
		return this.isvcSql.removeDatabaseAsync(CATALOG_DATABASE_ID);
	}

	public isUpToDateAsync(poVersionResponse: ICatalogVersionResponse): Promise<boolean> {
		const loNewDatasource = new SqlDataSource(CATALOG_DATABASE_ID, +poVersionResponse.version, poVersionResponse.url);

		if (this.isvcSql.isOpened(loNewDatasource)) return Promise.resolve(true);

		if (this.mbIsMobile)
			return this.isvcSql.databaseExistsAsync(poVersionResponse.version, CATALOG_DATABASE_ID);

		return Promise.resolve(false);
	}

	/** @implements */
	public downloadAndInstall$(poVersionResponse: ICatalogVersionResponse): Observable<UpdateEvent | undefined> {
		const loSqlDataSource: SqlDataSource = new SqlDataSource(CATALOG_DATABASE_ID, +poVersionResponse.version, poVersionResponse.url);

		return this.isvcProvider.provide$(loSqlDataSource)
			.pipe(
				mergeMap((poUpdateEvent?: UpdateEvent) => {
					if (this.mbIsMobile && poUpdateEvent?.state === EUpdateStatus.saved) {
						return this.displayDownloadDonePopup(poVersionResponse.version)
							.pipe(
								tap((poRestartChoice: IUiResponse<RestartChoice>) => poRestartChoice?.response === "now" ? ApplicationService.reloadApp() : undefined),
								mapTo(poUpdateEvent)
							);
					}
					else
						return of(poUpdateEvent);
				})
			);
	}

	/** Affiche la popup de fin de téléchargement.
	 * @param psVersion Verison du catalogue téléchargée.
	 */
	private displayDownloadDonePopup(psVersion: string): Observable<IUiResponse<RestartChoice>> {
		return this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsPopup({
			header: `Téléchargement du catalogue en version ${psVersion} terminé`,
			message: `Redémarrez ${ConfigData.appInfo.appName} pour profiter de la dernière version du catalogue !`,
			buttons: [
				{ text: "Plus tard", handler: () => UiMessageService.getCustomResponse<RestartChoice>("later") },
				{ text: "Maintenant", handler: () => UiMessageService.getCustomResponse<RestartChoice>("now") }
			] as AlertButton[]
		}));
	}

	//#endregion Managing

	//#endregion METHODS

}