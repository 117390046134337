import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PlatformService } from '../../../../services/platform.service';
import { ImageDmsIds } from '../../../dms/model/image-dms-ids';
import { ModalComponentBase } from '../../../modal';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { secure } from '../../../utils/rxjs/operators/secure';
import { EMediaType } from '../models/emedia-type';
import { ICatalogProductItem } from '../models/i-catalog-product-item';
import { CatalogService } from '../services/catalog.service';
import { ICatalogModalItemParams } from './models/i-catalog-modal-item-params';

@Component({
	selector: 'calao-catalog-modal-item',
	templateUrl: './catalog-modal-item.component.html',
	styleUrls: ['./catalog-modal-item.component.scss'],
})
export class CatalogModalItemComponent extends ModalComponentBase<void> implements ICatalogModalItemParams, OnInit {
	//#region FIELDS

	public static readonly C_LOG_ID = "CTLGMODALITEM.C::";

	/** @implements */
	public itemId!: string;
	public readonly observableItem = new ObservableProperty<ICatalogProductItem | undefined>();
	public dmsIds$: Observable<ImageDmsIds | undefined> = of(undefined);

	public isLoading = true;
	@ObserveProperty<CatalogModalItemComponent>({ sourcePropertyKey: "isLoading" })
	public readonly observableIsLoading = new ObservableProperty<boolean>();

	//#endregion FIELDS

	//#region METHODS

	public constructor(
		private readonly isvcCatalog: CatalogService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef,
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.checkRequiredInputs();

		const getItem$: Observable<ICatalogProductItem | undefined> = this.isvcCatalog.getItemFromId(this.itemId).pipe(
			tap((poItem?: ICatalogProductItem) => {
				if (!poItem) {
					this.raiseInitializationError(`${this.itemId} not found in catalog.`);
					return undefined;
				}
				else {
					this.isLoading = false;
				}
			}, (poError: Error) => {
				this.isLoading = false;
				this.raiseInitializationError(poError.message);
			}),
			secure(this));

		this.observableItem.bind(getItem$, this);

		this.dmsIds$ = this.getImageIds$();
	}

	private checkRequiredInputs(): void {
		if (!this.itemId) {
			this.raiseInitializationError("no item id.");
		}
	}

	/** Récupère le flux contenant les identifiants de l'image. */
	private getImageIds$(): Observable<ImageDmsIds | undefined> {
		return this.isvcCatalog.getMediasIdFromItemId(this.itemId, undefined, [EMediaType.photo])
			.pipe(
				map((poMediaByType: Map<EMediaType, string>) => {
					if (!poMediaByType.has(EMediaType.photo))
						return undefined;

					return new ImageDmsIds(poMediaByType.get(EMediaType.photo)!, []); // TODO gérer les différentes images (revoir requêtes).
				})
			);
	}

	private raiseInitializationError(psMessage: string): void {
		console.error(`${CatalogModalItemComponent.C_LOG_ID}Unable to initialize modal: ${psMessage}`);
		this.close();
	}

	//#endregion METHODS

}
