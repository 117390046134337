import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, defer, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { IDateRange } from '../../../../components/date/date-range-picker/model/IDateRange';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { DateHelper } from '../../../../helpers/dateHelper';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { ObjectHelper } from '../../../../helpers/objectHelper';
import { ESortOrder } from '../../../../model/ESortOrder';
import { IFormListDetailEvent } from '../../../forms/models/iform-list-detail-event';
import { IColumnListSortEvent } from '../../../lists/models/icolumn-list-sort-event';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { ISortOption } from '../../../sort/models/isort-option';
import { ISortSelectorResult } from '../../../sort/models/isort-selector-result';
import { IRange } from '../../../utils/models/models/irange';
import { secure } from '../../../utils/rxjs/operators/secure';
import { EEntityEntriesListCustomCriteriaType } from '../../models/eentity-entries-list-custom-criteria-type';
import { EEntityEntriesListItemType } from '../../models/eentity-entries-list-item-type';
import { Entity } from '../../models/entity';
import { IEntityDescriptor } from '../../models/ientity-descriptor';
import { IEntityEntriesListCustomCriteriaParams } from '../../models/ientity-entries-list-custom-criteria-params';
import { IEntityEntriesListDefinition } from '../../models/ientity-entries-list-definition';
import { IEntityEntriesListListItemParams } from '../../models/ientity-entries-list-list-item-params';
import { IEntityEntriesListParams } from '../../models/ientity-entries-list-params';
import { IEntityEntriesListSortOption } from '../../models/ientity-entries-list-sort-option';
import { IEntityEntriesListSortParams } from '../../models/ientity-entries-list-sort-params';
import { IEntityEntriesListTabItemParams } from '../../models/ientity-entries-list-tab-item-params';
import { EntitiesService } from '../../services/entities.service';

interface IFilterValues<T> {
	text?: string;
	sort?: ISortSelectorResult<keyof T>;
	createDate?: IDateRange;
}

@Component({
	selector: 'calao-entity-entries-list-base',
	templateUrl: './entity-entries-list-base.component.html',
	styleUrls: ['./entity-entries-list-base.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityEntriesListBaseComponent<T extends Entity> extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Événement lors du clic sur le détail d'un item. */
	@Output("onDetailClicked") private readonly moDetailClickedEvent = new EventEmitter<IFormListDetailEvent<T>>();

	/** Événement lors du clic sur un item. */
	@Output("onItemClicked") private readonly moItemClickedEvent = new EventEmitter<T>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Paramètres du formList. */
	@Input() public params?: IEntityEntriesListParams<T> | null;
	@ObserveProperty<EntityEntriesListBaseComponent<T>>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<IEntityEntriesListParams<T>>();

	public readonly observableSortResult = new ObservableProperty<ISortSelectorResult<keyof T> | undefined>();
	public readonly observableEntries = new ObservableArray<T>([]);
	public readonly observableFormDefinition = new ObservableProperty<IEntityEntriesListDefinition<T> | undefined>();
	public readonly observableSortOptions = new ObservableArray<ISortOption<keyof T>>(this.getSortOptions$().pipe(secure(this)));
	public readonly observableFilterValues = new ObservableProperty<IFilterValues<T> | undefined>({ sort: undefined, text: undefined });
	public readonly observableGridParams = new ObservableProperty<IEntityEntriesListTabItemParams | undefined>();
	public readonly observableListParams = new ObservableProperty<IEntityEntriesListListItemParams | undefined>();
	public readonly observableFilteredEntries = new ObservableArray<T>(this.getFilteredEntries$().pipe(secure(this)));
	public readonly observableHasModal = new ObservableProperty<boolean>(false);
	public readonly observableNbTmpResults = new ObservableProperty<number>(0);

	/** Clé de tri. */
	@Input() public sortKey?: keyof T | null;
	@ObserveProperty<EntityEntriesListBaseComponent<T>>({ sourcePropertyKey: "sortKey" })
	public readonly observableSortKey = new ObservableProperty<keyof T>();

	/** Ordre de tri. */
	@Input() public sortOrder?: ESortOrder | null;
	@ObserveProperty<EntityEntriesListBaseComponent<T>>({ sourcePropertyKey: "sortOrder" })
	public readonly observableSortOrder = new ObservableProperty<ESortOrder>();

	/** Indique pour chaque item si on doit afficher le bouton de détail. */
	@Input() public hasDetailById?: Map<string, boolean> | null;
	@ObserveProperty<EntityEntriesListBaseComponent<T>>({ sourcePropertyKey: "hasDetailById" })
	public readonly observableHasDetailById = new ObservableProperty<Map<string, boolean>>();

	/** Indique si on doit afficher le bouton de détail. */
	@Input() public hasDetail?: boolean | null;
	@ObserveProperty<EntityEntriesListBaseComponent<T>>({
		sourcePropertyKey: "hasDetail",
		transformer: coerceBooleanProperty
	})
	public readonly observableHasDetail = new ObservableProperty<boolean>();

	public readonly itemType = EEntityEntriesListItemType;

	/** `true` si le filtrage peut être validé, sinon `false`. */
	public readonly observableCanValidate = new ObservableProperty<boolean>(true);

	/** Nombre de filtre appliqués (la recherche textuelle ne compte pas comme un filtre). */
	public nbFiltersLabel$: Observable<string | undefined> = this.getNbFiltersLabel$().pipe(secure(this));

	public EEntityEntriesListCustomCriteriaType = EEntityEntriesListCustomCriteriaType;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntities: EntitiesService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);

		this.observableSortResult.bind(
			this.getSortResult$(),
			this
		);
	}

	public ngOnInit(): void {
		this.observableParams.value$.pipe(
			switchMap((poParams?: IEntityEntriesListParams<T>) =>
				defer(() =>
					poParams?.entityDescriptor ?
						of(poParams?.entityDescriptor) :
						this.isvcEntities.getDescriptor$(poParams?.entityDescId)
				).pipe(
					tap((poDesc: IEntityDescriptor) => this.initParams(poDesc, poParams)),
					mergeMap((poDesc: IEntityDescriptor) =>
						ArrayHelper.hasElements(this.observableEntries) ?
							of(this.observableEntries) :
							this.isvcEntities.getEntries$(
								poParams?.dataSource ?? this.isvcEntities.getDataSource(poDesc, poParams?.dataSourceId),
								poParams?.filters
							)
					),
					tap((paDocs: T[]) => this.observableEntries.resetArray(paDocs))
				)
			),
			secure(this)
		).subscribe();
	}

	public getSortResult$(): Observable<ISortSelectorResult<keyof T>> {
		return combineLatest([
			this.observableSortKey.value$,
			this.observableSortOrder.value$
		]).pipe(
			map(([poSortKey, peSortOrder]: [keyof T, ESortOrder]) => ({ order: peSortOrder, by: poSortKey }))
		);
	}

	private getSortOptions$(): Observable<ISortOption<keyof T>[]> {
		return this.observableFormDefinition.value$.pipe(
			map((poListDef: IEntityEntriesListDefinition<T>) =>
				poListDef?.sort?.options?.map((poOption: IEntityEntriesListSortOption<T>) => (
					{ value: poOption.key, label: poOption.label })
				) ?? []
			)
		)
	}

	private initParams(poDesc: IEntityDescriptor, poParams?: IEntityEntriesListParams<T>): void {
		// Récupération de la définition du formulaire.
		const loFormDefinition: IEntityEntriesListDefinition = poParams?.listDefinition ??
			this.isvcEntities.getListDefinition(poDesc, poParams?.listId) as IEntityEntriesListDefinition;
		this.observableFormDefinition.value = loFormDefinition;

		this.observableHasModal.value = ArrayHelper.hasElements(loFormDefinition.search?.customCriteria);

		switch (loFormDefinition.item.component) {
			case EEntityEntriesListItemType.tab:
				this.observableGridParams.value = loFormDefinition.item.params as IEntityEntriesListTabItemParams;
				break;

			case EEntityEntriesListItemType.list:
				this.observableListParams.value = loFormDefinition.item.params as IEntityEntriesListListItemParams;
				break;

			default:
				break;
		}

		if (ArrayHelper.hasElements(poParams?.customEntries))
			this.observableEntries.resetArray(poParams.customEntries);

		this.setDefaultSort(loFormDefinition);
	}

	private setDefaultSort(loFormDefinition: IEntityEntriesListDefinition<any>) {
		const loSortParams: IEntityEntriesListSortParams<T> | undefined = loFormDefinition.sort;
		this.sortOrder = loSortParams?.defaults.order || ESortOrder.ascending;
		const loSortKey: keyof T | undefined = loSortParams?.defaults.key;
		if (loSortKey)
			this.sortKey = loSortKey;
	}

	/** Trie le tableau des documents en fonction d'une clé. */
	private sortDocuments(paDocs: T[], poSortKey?: keyof T, peSortOrder?: ESortOrder): T[] {
		if (poSortKey)
			return ArrayHelper.dynamicSort(paDocs, poSortKey, peSortOrder);
		return paDocs;
	}

	/** Ordonne les entrées avec la clé en paramètre. Si la clé est déjà la sortKey, inverse la liste.
	 * @param poEvent Clé de tri des données.
	 */
	public orderOrReverse(poEvent: IColumnListSortEvent<T>): void {
		if (poEvent.key) {
			this.sortKey = poEvent.key;
			this.sortOrder = poEvent.order;
		}
	}

	public onDetailClicked(poItem: T, poEvent: MouseEvent): void {
		this.moDetailClickedEvent.emit({ event: poEvent, item: poItem });
	}

	public onItemClicked(poItem: T): void {
		this.moItemClickedEvent.emit(poItem);
	}

	public itemHasDetail(poItem: T, poHasDetailById?: Map<string, boolean> | null, pbHasDetail?: boolean | null): boolean {
		if (ObjectHelper.isDefined(pbHasDetail))
			return pbHasDetail;
		return !poHasDetailById || !!poHasDetailById.get(poItem._id);
	}

	public onFilterValuesChange(poFilterValues?: IFilterValues<T>): void {
		this.observableFilterValues.value = poFilterValues;
		this.observableNbTmpResults.value = 0;

		this.observableFilteredEntries.resetArray(this.filterEntries(this.observableEntries, poFilterValues));

		if (poFilterValues)
			this.observableSortResult.value = poFilterValues.sort;
		else if (this.observableFormDefinition.value)
			this.setDefaultSort(this.observableFormDefinition.value);
	}

	public onTmpFilterValuesChange(poFilterValues: IFilterValues<T>): void {
		this.observableNbTmpResults.value = this.filterEntries(this.observableEntries, poFilterValues).length;
	}

	private getFilteredEntries$(): Observable<T[]> {
		return combineLatest([
			this.observableEntries.changes$,
			this.observableFilterValues.value$
		]).pipe(
			map(([paEntries, poFilterValues]: [T[], IFilterValues<T> | undefined]) =>
				this.filterEntries(
					paEntries,
					poFilterValues
				)
			),
			switchMap((paEntries: T[]) => this.observableSortResult.value$.pipe(
				map((poSortResult?: ISortSelectorResult<keyof T>) => this.sortDocuments(
					paEntries,
					poSortResult?.by,
					poSortResult?.order
				))
			))
		);
	}

	private filterEntries(paEntries: T[], poFilterValues?: IFilterValues<T>): T[] {
		let loFilteredEntries: T[];
		loFilteredEntries = this.filterByText(paEntries, poFilterValues);
		loFilteredEntries = this.filterByDateRange(loFilteredEntries, poFilterValues);
		loFilteredEntries = this.filterByNumericRange(loFilteredEntries, poFilterValues);
		return loFilteredEntries;
	}

	public updateCanValidate(poRange: IDateRange) {
		this.observableCanValidate.value = DateHelper.isDate(poRange.from) && DateHelper.isDate(poRange.to);
	}

	private filterByText(paEntries: T[], poFilterValues?: IFilterValues<T>): T[] {
		return paEntries.filter((poEntry: T) => {
			if (this.observableFormDefinition.value?.search) {
				return this.observableFormDefinition.value?.search?.text?.keys?.some((psKey: string) => {
					const loValue: any = poEntry[psKey];

					if (typeof loValue === "string") {
						return !poFilterValues?.text ||
							loValue.toLowerCase().includes(poFilterValues.text.toLowerCase());
					}
					return false;
				});
			}
			else
				return paEntries;
		})
	}

	private filterByDateRange(paEntries: T[], poFilterValues?: IFilterValues<T>): T[] {
		if (ArrayHelper.hasElements(this.observableFormDefinition.value?.search?.customCriteria)) {
			const laCustomCriteria: IEntityEntriesListCustomCriteriaParams[] = [...this.observableFormDefinition.value.search.customCriteria];
			ArrayHelper.removeElementsByFinder(
				laCustomCriteria,
				(poCustomCriterion: IEntityEntriesListCustomCriteriaParams) => poCustomCriterion.type === EEntityEntriesListCustomCriteriaType.dateRange
			).forEach((poCustomCriterion: IEntityEntriesListCustomCriteriaParams) => {
				if (poFilterValues?.[poCustomCriterion.params.key]) {

					paEntries = paEntries.filter((poEntry: T) => {
						const loValue: any = poEntry[poCustomCriterion.params.key];

						return DateHelper.isDateInRange(loValue, poFilterValues?.[poCustomCriterion.params.key]);
					})
				}
				return true;
			})
		}

		return paEntries;
	}

	private filterByNumericRange(paEntries: T[], poFilterValues?: IFilterValues<T>): T[] {
		if (ArrayHelper.hasElements(this.observableFormDefinition.value?.search?.customCriteria)) {
			const laCustomCriteria: IEntityEntriesListCustomCriteriaParams[] = [...this.observableFormDefinition.value.search.customCriteria];
			ArrayHelper.removeElementsByFinder(
				laCustomCriteria,
				(poCustomCriterion: IEntityEntriesListCustomCriteriaParams) => poCustomCriterion.type === EEntityEntriesListCustomCriteriaType.numericRange
			).forEach((poCustomCriterion: IEntityEntriesListCustomCriteriaParams) => {
				if (poFilterValues?.[poCustomCriterion.params.key]) {

					paEntries = paEntries.filter((poEntry: T) => {
						const loValue: any = poEntry[poCustomCriterion.params.key];
						const loRange: IRange<number> = poFilterValues?.[poCustomCriterion.params.key];

						if (loRange.from && !loRange.to)
							return NumberHelper.compare(loValue, loRange.from) >= 0
						else if (loRange.to && !loRange.from)
							return NumberHelper.compare(loValue, loRange.to) <= 0

						return NumberHelper.isInRange(loValue, loRange);
					})
				}
				return true;
			})
		}

		return paEntries;
	}

	private getNbFiltersLabel$(): Observable<string | undefined> {
		return this.observableFilterValues.value$.pipe(
			map((poValues: IFilterValues<T>) => {
				const lnFilterNumber: number = Object.keys(
					ObjectHelper.omit(poValues, ["sort", "text"])
				).filter((psKey: keyof IFilterValues<T>) => this.isFilterSet(poValues, psKey)).length;

				return lnFilterNumber > 0 ? `${lnFilterNumber}` : undefined;
			})
		);
	}

	private isFilterSet(poValues: IFilterValues<T>, psKey: keyof IFilterValues<T>): boolean {

		const loValue: any = poValues[psKey];

		if (loValue instanceof Array)
			return ArrayHelper.hasElements(loValue);

		return ObjectHelper.isDefined(poValues[psKey]);
	}

	//#endregion

}
