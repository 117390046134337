import { AsyncPipe, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DmsModule } from '../../../dms/dms.module';
import { ModalModule } from '../../../modal';
import { CatalogModalItemComponent } from './catalog-modal-item.component';

@NgModule({
	declarations: [CatalogModalItemComponent],
	imports: [
		IonicModule,
		ModalModule,
		DmsModule,
		AsyncPipe,
		CommonModule
	]
})
export class CatalogModalItemModule { }
